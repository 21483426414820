import { DecisionStatus, MainKeywordEnum, ValidationStatus } from '../firebaseRecords';
import { LawType } from '../firebaseRecords/lawTexts';
import { LawScope } from '../lawScope';
import { ModelType } from '../modelType';

export enum ClassName {
    DECISION = 'DECISION',
    LEGIS = 'LEGIS',
}

export interface AlgoliaBase {
    baseObjectID: string;
    fullText: string;
    language: string;
    lawScopes: LawScope[];
    objectID: string;
    sortByDate: number;
    subtitle?: string;
    title: string;
    updatedAt: number;
}

export interface SearchablePublicationSheet extends AlgoliaBase {
    createdAt: number;
    isPublished: boolean;
    modelType: ModelType.PUBLICATION_SHEET;
    notes: string[];
    path: string[];
    pathTitles: string[];
    treeId: string;
    'keywordHierarchy.lvl0'?: string;
    'keywordHierarchy.lvl1'?: string;
    'keywordHierarchy.lvl2'?: string;
    'keywordHierarchy.lvl3'?: string;
    'keywordHierarchy.lvl4'?: string;
    'keywordHierarchy.lvl5'?: string;
    'keywordHierarchy.lvl6'?: string;
    'keywordHierarchy.lvl7'?: string;
    'keywordHierarchy.lvl8'?: string;
    'keywordHierarchy.lvl9'?: string;
    'keywordHierarchy.lvl10'?: string;
    'keywordHierarchy.lvl11'?: string;
    'keywordHierarchy.lvl12'?: string;
    'keywordHierarchy.lvl13'?: string;
    'keywordHierarchy.lvl14'?: string;
}

export type SearchableAnalysisInDecision = Pick<
    SearchableAnalysis,
    'baseObjectID' | 'fullText' | 'pathKeywords' | 'pathNodeIds' | 'title' | 'treeId'
> & { position: number };

export interface SearchableDecision extends AlgoliaBase {
    analyses: SearchableAnalysisInDecision[];
    analysisCount: number;
    biblio: string[];
    city: string;
    createdAt: number;
    decisionStatus: DecisionStatus;
    decisionType: string | string[];
    juridictionName: string;
    juridictionRoom: string;
    jury: string[];
    legislation: string[];
    modelType: ModelType.DECISION;
    normalizedNumber?: string[];
    number: string[];
    performedAt: number;
    result: string;
    'juridictionHierarchy.lvl0'?: string;
    'juridictionHierarchy.lvl1'?: string;
    'juridictionHierarchy.lvl2'?: string;
    'juridictionHierarchy.lvl3'?: string;
}

export interface SearchableAnalysis extends AlgoliaBase {
    decisionId: string;
    isPublished: boolean;
    modelType: ModelType.ANALYSIS;
    pathKeywords: string[];
    pathNodeIds: string[];
    treeId: string;
    validationStatus: ValidationStatus;
    'juridictionHierarchy.lvl0'?: string;
    'juridictionHierarchy.lvl1'?: string;
    'juridictionHierarchy.lvl2'?: string;
    'juridictionHierarchy.lvl3'?: string;
    'keywordHierarchy.lvl0'?: string;
    'keywordHierarchy.lvl1'?: string;
    'keywordHierarchy.lvl2'?: string;
    'keywordHierarchy.lvl3'?: string;
    'keywordHierarchy.lvl4'?: string;
    'keywordHierarchy.lvl5'?: string;
    'keywordHierarchy.lvl6'?: string;
    'keywordHierarchy.lvl7'?: string;
    'keywordHierarchy.lvl8'?: string;
    'keywordHierarchy.lvl9'?: string;
    'keywordHierarchy.lvl10'?: string;
    'keywordHierarchy.lvl11'?: string;
    'keywordHierarchy.lvl12'?: string;
    'keywordHierarchy.lvl13'?: string;
    'keywordHierarchy.lvl14'?: string;
}

export interface SearchableLawText extends AlgoliaBase {
    article: string;
    createdAt: number;
    issuer: string;
    lawType: LawType | string;
    modelType: ModelType.LAW_TEXT;
    nature: string;
    normalizedNumber?: string[];
    number: string;
    path: string[];
    pathTitles: string[];
    performedAt: number;
    treeId: string;
    type: string;
}

export interface SearchableArticle extends AlgoliaBase {
    createdAt: number;
    decisions: string[];
    isPublished: boolean;
    modelType: ModelType.ARTICLE;
}

export interface SearchableGlossaryTerm extends AlgoliaBase {
    createdAt: number;
    description?: string;
    firstLetter: string;
    isRedirection: boolean;
    linkedBiblio: string[];
    linkedDecisions: string[];
    linkedStatistics: string[];
    mainKeywords: MainKeywordEnum[];
    modelType: ModelType.GLOSSARY_TERM;
    pathKeywords: string[][];
    redirectionId?: string;
    treeId: string[];
    'keywordHierarchy.lvl0'?: string[];
    'keywordHierarchy.lvl1'?: string[];
    'keywordHierarchy.lvl2'?: string[];
    'keywordHierarchy.lvl3'?: string[];
    'keywordHierarchy.lvl4'?: string[];
    'keywordHierarchy.lvl5'?: string[];
    'keywordHierarchy.lvl6'?: string[];
    'keywordHierarchy.lvl7'?: string[];
    'keywordHierarchy.lvl8'?: string[];
    'keywordHierarchy.lvl9'?: string[];
    'keywordHierarchy.lvl10'?: string[];
    'keywordHierarchy.lvl11'?: string[];
    'keywordHierarchy.lvl12'?: string[];
    'keywordHierarchy.lvl13'?: string[];
    'keywordHierarchy.lvl14'?: string[];
}

export interface SearchableStatistic extends AlgoliaBase {
    category: string;
    createdAt: number;
    isPublished: boolean;
    modelType: ModelType.STATISTIC;
    type: string;
}

export type SearchableRecord =
    | SearchableAnalysis
    | SearchableArticle
    | SearchableDecision
    | SearchableGlossaryTerm
    | SearchableLawText
    | SearchablePublicationSheet
    | SearchableStatistic;
