export enum CollectionName {
    ANALYSES = 'analyses',
    ARTICLES = 'articles',
    BANNER_NOTIFICATIONS = 'bannerNotifications',
    CITATIONS = 'citations',
    CODE_ARTICLES = 'codeArticles',
    DECISIONS = 'decisions',
    DELETED_CODE_ARTICLES = 'deletedCodeArticles',
    DELETED_DECISIONS = 'deletedDecisions',
    DECISION_REQUESTS = 'decisionRequests',
    DELETED_USERS = 'deletedUsers',
    DOCUMENTS_COUNTER = 'documentsCounter',
    DYNAMIC_FLAGS = 'dynamicFlags',
    EMAIL_RECIPIENTS = 'emailRecipients',
    GLOSSARY_TERMS = 'glossaryTerms',
    KEYWORD_NODES = 'keywordNodes',
    LATEXT_TEMPLATES = 'laTeXTemplates',
    LAW_TEXTS = 'laws',
    MIGRATIONS = 'migrations',
    PING = 'ping',
    PUBLICATION_SHEETS = 'publicationSheets',
    PUBLIC_ACCESS = 'publicAccess',
    SCHEMAS = 'schemas',
    SITEMAP = 'sitemap',
    STATISTICS = 'statistics',
    STATISTIC_ENTRIES = 'statisticEntries',
    TREES = 'trees',
    TREE_NODES = 'treeNodes',
    USAGE_STATS = 'usageStats',
    USERS = 'users',
    USER_PRIVILEGES = 'userPrivileges',
}

export enum StorageCollectionName {
    DECISIONS_FULL_TEXT = 'decisionsFullText',
    LAWS_FULL_TEXT = 'lawsFullText',
}

export enum UserSubCollectionName {
    REVISION = 'revisions',
    SAVED_SEARCHES = 'savedSearches',
}

export enum UsageStatsSubCollectionName {
    USER_STATS = 'userStats',
}

export const PREMIUM_COLLECTIONS: CollectionName[] = [
    CollectionName.ANALYSES,
    CollectionName.ARTICLES,
    CollectionName.BANNER_NOTIFICATIONS,
    CollectionName.GLOSSARY_TERMS,
    CollectionName.LAW_TEXTS,
    CollectionName.SCHEMAS,
    CollectionName.STATISTICS,
    CollectionName.STATISTIC_ENTRIES,
];

export const PUBLIC_COLLECTIONS: CollectionName[] = [
    CollectionName.CITATIONS,
    CollectionName.CODE_ARTICLES,
    CollectionName.DECISIONS,
    CollectionName.PUBLICATION_SHEETS,
    CollectionName.TREES,
];

export const COMMON_COLLECTIONS: CollectionName[] = [...PREMIUM_COLLECTIONS, ...PUBLIC_COLLECTIONS];
